import axios from 'axios';
import CryptoJS from 'crypto-js';
import { Configuration, Calendar } from './models';

const apiServer = process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : '';

const bearer = 'Bearer ' + process.env.REACT_APP_API_KEY;

export const fetchConfig = async (password:string): Promise<Configuration> => {

    // initialize md5Password with an md5 hash of the password
    const md5Password = CryptoJS.MD5(password).toString();

    const defaultCalendar: Calendar = { id: new Date().getDate().toString(), eventTypes: [], events: [], persons: [] };
    const configUrl = `${apiServer}/config?password=${md5Password}`;
    //console.debug(configUrl);

    const response = await axios.get<Configuration>(configUrl);
    const newConfiguration = response.data ?? { name: 'Default', password: md5Password, calendars: [defaultCalendar] };
    newConfiguration.password = md5Password;
    newConfiguration.calendars = newConfiguration.calendars ?? [];
    newConfiguration.calendars = newConfiguration.calendars.length === 0 ? [defaultCalendar] : newConfiguration.calendars;
    
    return newConfiguration;
};


export const storeConfig = async (configuration: Configuration) => {
    const configUrl = `${apiServer}/config`;
    await axios.post(configUrl, configuration);
}
