import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import { EventType } from '../models';
import EmojiDialog from './EmojiDialog';
import { Emoji } from 'emoji-picker-react';

interface AddEventTypeDialogProps {
    open: boolean;
    onAddEventType: (eventType: EventType) => void;
    onClose: () => void;
}

const AddEventTypeDialog: React.FC<AddEventTypeDialogProps> = ({ open, onAddEventType, onClose }) => {
    const [currentEventType, setCurrentEventType] = useState<EventType>({ id: new Date().getTime().toString() } as EventType);
    const [showEmojiSelector, setShowEmojiSelector] = useState(false);

    const changeValue = (targetField: string, value: string) => {
        setCurrentEventType({ ...currentEventType, [targetField]: value });
    };

    const handleShowEmojiSelector = () => {
        setShowEmojiSelector(true);
    };

    const handleSelectEmoji = (emoji: string) => {
        changeValue("emoticon", emoji);
        setShowEmojiSelector(false);
    };

    const handleAddEventType = () => {
        onAddEventType(currentEventType);
        setCurrentEventType({ id: new Date().getTime().toString() } as EventType);
        onClose();
    };

    const handleCancel = () => {
        setCurrentEventType({ id: new Date().getTime().toString() } as EventType);
        onClose();
    };

    return (
        <>
            <Dialog open={open}>
                <DialogTitle>Add Event Type</DialogTitle>
                <DialogContent>
                    <Stack direction="column" spacing={2}>
                        <Stack direction="row" spacing={2} padding={1}>                            
                            <Emoji unified={currentEventType.emoticon} size={50} />
                            <TextField label="Name" onChange={(e) => changeValue("name", e.target.value)} autoCorrect="off" spellCheck="false" autoComplete="off" data-lpignore="true"/>
                        </Stack>                        
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={handleShowEmojiSelector}>Select Emoticon</Button>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button onClick={handleAddEventType}>Add</Button>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
            <EmojiDialog 
                open={showEmojiSelector} 
                onSelectEmoji={handleSelectEmoji} 
                onClose={() => setShowEmojiSelector(false)} 
            />
        </>
    );
};

export default AddEventTypeDialog;
