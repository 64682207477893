import React from 'react';
import { EventType } from '../models';
import { Emoji } from 'emoji-picker-react';
import { Stack, Typography } from '@mui/material';
import EditEventTypeDialog from './EditEventTypeDialog';


const ExternalEventType = ({ eventType, onEventChange, onEventDelete }: { eventType: EventType, onEventChange: any, onEventDelete: any }) => {  
  const [editEventOpen, setEditEventOpen] = React.useState(false);

  const handleOnClick = () => setEditEventOpen(true);
  const handleEventTypeChange = (eventType: EventType) => {
    setEditEventOpen(false);
    onEventChange(eventType);
  }
  const handleEventTypeDelete = () => {
    setEditEventOpen(false);
    onEventDelete(eventType);
  }
  const handleEditEventClose = () => setEditEventOpen(false);  

  return <>
    <EditEventTypeDialog 
      open={editEventOpen}
      eventType={eventType}
      onSave={handleEventTypeChange}
      onDelete={handleEventTypeDelete}
      onCancel={handleEditEventClose}
      />
    <Stack
      data-key={eventType.id}
      direction="row"
      spacing={2}
      sx={{
        backgroundColor: '#377dff',
        color: '#fff',
        padding: '5px 10px',
        margin: '5px 0',
        cursor: 'pointer',
        fontSize: '20pt',
        fontFamily: 'Arial, sans-serif',
        borderRadius: '5px',        
      }}
      className="fc-event"      
      onClick={handleOnClick}
    >
      <Stack direction="row" spacing={1}>
        <Emoji unified={eventType.emoticon} size={24} />
        <Typography variant="body1">{eventType.name}</Typography>
      </Stack>
    </Stack>
  </>
};

export default ExternalEventType;
