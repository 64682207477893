import React from 'react';
import { Event, EventType } from '../models';
import { Emoji } from 'emoji-picker-react';
import { Stack, Typography } from '@mui/material';

const ExternalEvent = ({ event, eventTypes }: { event: Event, eventTypes: EventType[] | undefined }) => {  
  const currentEventType = eventTypes?.find((eventType) => eventType.id === event.eventTypeId);  
  
  return (
    <div>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          backgroundColor: '#377dff',
          color: '#fff',
          padding: '3px',
          borderRadius: '5px',
          cursor: 'pointer',
          fontSize: '12pt',
          fontFamily: 'Arial, sans-serif',
        }}
      >
        <Stack direction="row" spacing={1}>
          <Emoji unified={currentEventType?.emoticon||''} size={15}/>
          <Typography variant="body2">{currentEventType?.name}</Typography>
        </Stack>
      </Stack>
    </div>
  );
};

export default ExternalEvent;
