import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { EventContentArg } from '@fullcalendar/core';
import { Event, Calendar } from '../models';
import ExternalEvent from './ExternalEvent';
import EditEventDialog from './EditEventDialog';

interface EventCalendarProps {
    calendar: Calendar;
    onEventAdd: any;
    onEventDelete: any;
}

const EventCalendar: React.FC<EventCalendarProps> = ({ calendar, onEventAdd, onEventDelete,  }) => {
    const calendarRef = useRef<FullCalendar>(null);
    const [selectedEvent, setSelectedEvent] = useState<Event>();
    const [showEventEditDialog, setShowEventEditDialog] = useState(false);

    const handleDateClick = (arg: { dateStr: any; }) => {                
    };

    const renderEventContent = (eventContent: EventContentArg) => <ExternalEvent event={eventContent.event.extendedProps as Event} eventTypes={calendar.eventTypes}/>
    
    const handleEventReceive = (info: any) => {
        const key = info.draggedEl.dataset.key;
        const eventType = calendar.eventTypes.find((eventType) => eventType.id === key);
        const newEvent = {
            id: new Date().getTime().toString(),
            date: info.event.startStr,
            name: "",//eventType?.name,
            emoticon: eventType?.emoticon,
            eventTypeId: eventType?.id,
            personId: "",
        } as Event;

        onEventAdd(newEvent);
        info.revert();

        calendarRef.current?.getApi().removeAllEventSources();
        calendarRef.current?.getApi().addEventSource(calendar.events);
    };

    const handleDrop = (info: any) => {
        var eventToUpdate = calendar.events.find((event) => event.id === info.event.id)!;
        eventToUpdate.date = info.event.startStr
        onEventAdd(eventToUpdate);
    }

    const onEventEditClose = () => {
        setShowEventEditDialog(false);
    }

    const onEventEditDelete = (eventToRemove: Event) => {
        onEventDelete(eventToRemove);
        setShowEventEditDialog(false);
    }

    const handleEventEdit = (info: any) => {
        const eventToUpdate = calendar.events.find((event) => event.id === info.event.id)!;
        setSelectedEvent(eventToUpdate);
        setShowEventEditDialog(true);
    }

    return <>    
            <EditEventDialog 
                open={showEventEditDialog}
                event={selectedEvent}
                onDelete={onEventEditDelete}
                onClose={onEventEditClose}
            />
            <FullCalendar
                ref={calendarRef}
                events={calendar.events}
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                selectable={true}
                editable={true}
                droppable={true}
                eventStartEditable={true}
                eventDurationEditable={false}
                contentHeight={600}            
                eventContent={renderEventContent}
                dateClick={handleDateClick}
                eventReceive={handleEventReceive}
                eventDrop={handleDrop}
                eventClick={handleEventEdit}
            />
        </>;
};

export default EventCalendar;
