import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import { EventType } from '../models';
import EmojiDialog from './EmojiDialog';
import { Emoji } from 'emoji-picker-react';

interface EditEventTypeDialogProps {
    open: boolean;
    eventType: EventType;
    onSave: (eventType: EventType) => void;
    onDelete: (eventType: EventType) => void;
    onCancel: () => void;
}

const EditEventTypeDialog: React.FC<EditEventTypeDialogProps> = ({ open, eventType, onSave, onDelete, onCancel }) => {
    const [currentEventType, setCurrentEventType] = useState<EventType>({...eventType} as EventType);
    const [showEmojiSelector, setShowEmojiSelector] = useState(false);

    const changeValue = (targetField: string, value: string) => {
        setCurrentEventType({ ...currentEventType, [targetField]: value });
    };
    
    const handleShowEmojiSelector = () => setShowEmojiSelector(true);
    const handleEmojiSelectorClose = () => setShowEmojiSelector(false);
    const handleSelectEmoji = (emoji: string) => {
        changeValue("emoticon", emoji);
        setShowEmojiSelector(false);
    };
    
    const handleSave = () => onSave(currentEventType);
    const handleDelete = () => onDelete(currentEventType);
    const handleCancel = () => onCancel();

    return (
        <>
            <Dialog open={open}>
                <DialogTitle>Edit Event Type</DialogTitle>
                <DialogContent>
                    <Stack direction="column" spacing={2}>
                        <Stack direction="row" spacing={2} padding={1}>
                            <Emoji unified={currentEventType.emoticon} size={50} />
                            <TextField label="Name" onChange={(e) => changeValue("name", e.target.value)} value={currentEventType.name} autoCorrect="off" spellCheck="false" autoComplete="off" data-lpignore="true"/>
                        </Stack>                        
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={handleShowEmojiSelector}>Select Emoticon</Button>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button onClick={handleDelete}>Delete</Button>
                            <Button onClick={handleSave}>Save</Button>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
            <EmojiDialog 
                open={showEmojiSelector} 
                onSelectEmoji={handleSelectEmoji} 
                onClose={handleEmojiSelectorClose} 
            />
        </>
    );
};

export default EditEventTypeDialog;
