import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';

interface PasswordDialogProps {
    open: boolean;
    onPasswordEntered: (password:string) => void;
    onClose: () => void;
}

const PasswordDialog: React.FC<PasswordDialogProps> = ({ open, onPasswordEntered, onClose }) => {
    const [password, setPassword] = useState('');
    
    const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);
    const handleCancel = () =>  onClose();
    const handleEnterPassword = () => onPasswordEntered(password);
    const handleEnterKey = (e: React.KeyboardEvent) => { if (e.key === 'Enter') handleEnterPassword(); };

    return (
        <form autoComplete="off">
            <Dialog open={open}>
                <DialogTitle>Enter calendar password</DialogTitle>
                <DialogContent>
                    <Stack direction="column" spacing={2}>
                        <Stack direction="row" spacing={2} padding={1}>                            
                            <TextField
                                label="Password" type='password' 
                                name="nip"
                                onChange={handleChangeValue} 
                                onKeyDown={handleEnterKey}
                                autoCorrect="off" spellCheck="false" autoComplete="off"
                                inputProps={{ 'data-lpignore': 'true' }}
                                />
                        </Stack>                        
                        <Stack direction="row" spacing={2}>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button onClick={handleEnterPassword}>Open Calendar</Button>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
        </form>
    );
};

export default PasswordDialog;
