import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Calendar, Configuration, Event, EventType } from './models';
import { EventCalendar, ExternalEvents, AddEventTypeDialog, PasswordDialog } from './components';
import { fetchConfig, storeConfig } from './sdk';
import "./App.css";

const App: React.FC = () => {
    const defaultCalendar: Calendar = { id: 'default', eventTypes: [], events: [], persons: [] };
    const [configuration, setConfiguration] = useState<Configuration | null>(null);   
    
    const [showPasswordDialog, setShowPasswordDialog] = useState(true);
    
    const [showEventTypeDialog, setShowEventTypeDialog] = useState(false);
    const [currentCalendar, setCurrentCalendar] = useState<Calendar>(defaultCalendar);

    const handlePasswordEntered = async (password: string) => {
        const newConfiguration = await fetchConfig(password);
        setConfiguration(newConfiguration);

        // reset all events without date to the current date from the calendar
        newConfiguration.calendars[0].events = newConfiguration.calendars[0].events.map((event) => {
            if(!event.date) {
                event.date = new Date().toISOString();
            }
            return event;
        });

        setCurrentCalendar(newConfiguration.calendars[0]);
        setShowPasswordDialog(false);
        
        console.debug('Configuration loaded', newConfiguration);
    }

    const handlePasswordClosed = () => setShowPasswordDialog(false);

    const handleAddEventType = (newEventType: EventType) => {
        const eventTypeToUpdate = currentCalendar.eventTypes.find((eventType) => eventType.id === newEventType.id);

        if(!eventTypeToUpdate) {
            currentCalendar.eventTypes.push(newEventType);
            console.debug('Event Type added', newEventType);
        }
        else 
        {
            eventTypeToUpdate.name = newEventType.name;
            eventTypeToUpdate.emoticon = newEventType.emoticon;
            console.debug('Event Type updated', eventTypeToUpdate);
        }   
        
        setConfiguration(configuration);
        storeConfig(configuration!);
    };

    const handleEventTypeChange = (eventType: EventType) => {
        const eventTypeToUpdate = currentCalendar.eventTypes.find((event) => event.id === eventType.id)!;
        eventTypeToUpdate.name = eventType.name;
        eventTypeToUpdate.emoticon = eventType.emoticon;
        setConfiguration(configuration => ({...configuration!}));
        storeConfig(configuration!);
    }

    const handleEventTypeDelete = (eventType: EventType) => {
        currentCalendar.events = currentCalendar.events.filter((event) => event.eventTypeId !== eventType.id);
        currentCalendar.eventTypes = currentCalendar.eventTypes.filter((event) => event.id !== eventType.id);
        setConfiguration(configuration => ({...configuration!}));
        storeConfig(configuration!);
    }

    const handleEventAdd = (newEvent: any) => {
        const eventToUpdate = currentCalendar.events.find((event) => event.id === newEvent.id)!;

        if(!eventToUpdate) {
            currentCalendar.events.push(newEvent);
            console.debug('Event added', newEvent);
        }else{            
            eventToUpdate.date = newEvent.date;
            console.debug('Event updated', eventToUpdate);
        }
        setConfiguration(configuration => ({...configuration!}));
        storeConfig(configuration!);
    }

    const onEventEditDelete = (eventToRemove: Event) => {        
        currentCalendar.events = currentCalendar.events.filter((event) => event.id !== eventToRemove.id);
        setConfiguration(configuration => ({...configuration!}));
        storeConfig(configuration!);
    }

    return (
        <Box sx={{ height: '100vh', width: '100vw' }}>
            <PasswordDialog
                open={showPasswordDialog}
                onPasswordEntered={handlePasswordEntered}
                onClose={handlePasswordClosed}
            />
            <AddEventTypeDialog
                open={showEventTypeDialog}
                onAddEventType={handleAddEventType}
                onClose={() => setShowEventTypeDialog(false)}
            />
            <Grid container p={2}>
                <Grid item xs={10} p={1}>
                    <EventCalendar calendar={currentCalendar} onEventAdd={handleEventAdd} onEventDelete={onEventEditDelete} />
                </Grid>
                <Grid item xs={2} p={1}>
                    <ExternalEvents calendar={currentCalendar} onEventChange={handleEventTypeChange} onEventDelete={handleEventTypeDelete} />
                    <Button onClick={() => setShowEventTypeDialog(true)}>Add Event Type</Button>
                    <Typography>Event Types: {currentCalendar.eventTypes.length}</Typography>
                    <Typography>Events: {currentCalendar.events.length}</Typography>
                </Grid>
                
            </Grid>            
        </Box>
    );
};

export default App;
