import { Box } from '@mui/material';
import { Calendar, EventType } from '../models';
import { ExternalEventType } from '.';
import { useEffect, useRef, useMemo } from 'react';
import { Draggable } from '@fullcalendar/interaction';

interface IExternalEventTypesProps 
{ 
    calendar: Calendar | undefined,
    onEventChange: (eventType: EventType) => void,
    onEventDelete: (eventType: EventType) => void
}

const ExternalEventTypes = ({ calendar, onEventChange, onEventDelete }: IExternalEventTypesProps) =>  {
    const externalEventsRef = useRef(null);
    const handleEventTypeChange = (eventType: EventType) => onEventChange(eventType);      
    const handleEventTypeDelete = (eventType: EventType) => onEventDelete(eventType);    
    
    useEffect(() => {
        if (externalEventsRef.current) {
          new Draggable(externalEventsRef.current, {
            itemSelector: '.fc-event',
            eventData: function(eventEl) {
                return { key: eventEl.dataset.key };
            },
          });
        }
      }, []);   

      const sortedEventTypes = useMemo(() => {
        if (!calendar) return [];

        const eventTypeCounts = calendar.events.reduce((acc, event) => {
            acc[event.eventTypeId] = (acc[event.eventTypeId] || 0) + 1;
            return acc;
        }, {} as Record<string, number>);

        return calendar.eventTypes.sort((a, b) => 
            (eventTypeCounts[b.id] || 0) - (eventTypeCounts[a.id] || 0)
        );
    }, [calendar]);

    return (
        <Box ref={externalEventsRef} >
            {sortedEventTypes?.map((event) => 
              <ExternalEventType key={event.id} eventType={event} onEventChange={handleEventTypeChange} onEventDelete={handleEventTypeDelete} />
            )}
        </Box>
    );
};

export default ExternalEventTypes;