import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';

interface EmojiDialogProps {
    open: boolean;
    onSelectEmoji: (emoji: string) => void;
    onClose: () => void;
}

const EmojiDialog: React.FC<EmojiDialogProps> = ({ open, onSelectEmoji, onClose }) => {
    const [selectedEmoji, setSelectedEmoji] = useState<string | null>(null);

    const handleSelectEmoji = (emojiData: EmojiClickData) => {
        setSelectedEmoji(emojiData.unified);
        onSelectEmoji(emojiData.unified);
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle>Select Emoticon</DialogTitle>
            <DialogContent>
                <EmojiPicker onEmojiClick={handleSelectEmoji} />
                <Button onClick={onClose}>Close</Button>
            </DialogContent>
        </Dialog>
    );
};

export default EmojiDialog;
