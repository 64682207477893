import React from 'react';
import { Event } from '../models';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface EditEventDialogProps {
    open: boolean;
    event: Event | undefined;
    onClose: () => void;
    onDelete: (eventToRemove: Event) => void;
}

const EditEventDialog: React.FC<EditEventDialogProps> = ({ open, event, onClose, onDelete }) => {

    const onDeleteEvent = () => {
        if(event) onDelete(event);
    }

    return (
        <Dialog open={open}>
            <DialogTitle>Edit Event</DialogTitle>
            <DialogContent>

            </DialogContent>
            <DialogActions>                
                <Button onClick={onDeleteEvent}>Delete</Button>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditEventDialog;